<template>
  <div class="tui tuim ui middle aligned center aligned grid">
    <div class="tui tuim column">
      <t-auth-header description="Digite uma nova senha e depois insira a mesma novamente nos campos abaixo" />

      <div class="tui tuim ui card">
        <form :class="`tui tuim ui large form ${error ? 'error' : ''}`" @submit.prevent="savePassword()">
          <div class="content left aligned spacer">

            <div :class="`field ${error ? 'error' : ''}`">
              <label for="autoPass1">Nova senha</label>
              <input type="password" id="autoPass1" name="pass1" v-model="form.pass1" placeholder="•••••••••••" />
            </div>
            <div :class="`field ${error ? 'error' : ''}`">
              <label for="authPass2">Repita a senha</label>
              <input type="password" id="authPass2" name="pass2" v-model="form.pass2" placeholder="•••••••••••" />
            </div>

            <button
              type="submit"
              :class="`tui tuim ui primary large fluid ${isLoading ? 'loading' : ''} ${isLoading ? 'disabled' : ''} button`"
              v-text="`Alterar senha`"
              :disabled="isLoading"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import TAuthHeader from '@/components/auth/header.vue'

export default {
  name: 'TAuthEditPassword',
  title: ({ title }) => title,
  data () {
    return {
      title: `Recuperar senha`,
      form: {
        pass1: '',
        pass2: ''
      },
      isLoading: false,
      error: false
    }
  },
  methods: {
    savePassword() {}
  },
  components: {
    TAuthHeader
  }
}
</script>
