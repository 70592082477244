var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tui tuim ui middle aligned center aligned grid" },
    [
      _c(
        "div",
        { staticClass: "tui tuim column" },
        [
          _c("t-auth-header", {
            attrs: {
              description:
                "Digite uma nova senha e depois insira a mesma novamente nos campos abaixo"
            }
          }),
          _c("div", { staticClass: "tui tuim ui card" }, [
            _c(
              "form",
              {
                class: "tui tuim ui large form " + (_vm.error ? "error" : ""),
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.savePassword()
                  }
                }
              },
              [
                _c("div", { staticClass: "content left aligned spacer" }, [
                  _c("div", { class: "field " + (_vm.error ? "error" : "") }, [
                    _c("label", { attrs: { for: "autoPass1" } }, [
                      _vm._v("Nova senha")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.pass1,
                          expression: "form.pass1"
                        }
                      ],
                      attrs: {
                        type: "password",
                        id: "autoPass1",
                        name: "pass1",
                        placeholder: "•••••••••••"
                      },
                      domProps: { value: _vm.form.pass1 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "pass1", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("div", { class: "field " + (_vm.error ? "error" : "") }, [
                    _c("label", { attrs: { for: "authPass2" } }, [
                      _vm._v("Repita a senha")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.pass2,
                          expression: "form.pass2"
                        }
                      ],
                      attrs: {
                        type: "password",
                        id: "authPass2",
                        name: "pass2",
                        placeholder: "•••••••••••"
                      },
                      domProps: { value: _vm.form.pass2 },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "pass2", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("button", {
                    class:
                      "tui tuim ui primary large fluid " +
                      (_vm.isLoading ? "loading" : "") +
                      " " +
                      (_vm.isLoading ? "disabled" : "") +
                      " button",
                    attrs: { type: "submit", disabled: _vm.isLoading },
                    domProps: { textContent: _vm._s("Alterar senha") }
                  })
                ])
              ]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }